@import (reference) '../../styles/media.less';

.hero {
  background-image: url('./hero_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 24px;
  background-position: 40% center;
}

.heroContent {
  display: flex;
  flex-direction: column;

  @media @sm {
    flex-direction: row-reverse;
  }
}

.heroText {
  @media @sm {
    flex: 1 1 auto;
    padding-top: 12px;
  }
}

.h1 {
  font-size: 24px;
  line-height: 1.1;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 2px 2px rgba(0, 44, 58, 0.5);

  @media @sm {
    text-align: right;
  }

  em {
    display: block;
    font-size: 40px;
    font-style: normal;
    margin-bottom: 16px;
    @media @sm {
      margin-bottom: 0;
    }
  }
}

.heroPhone {
  display: flex;
  justify-content: center;
  @media @sm {
    justify-content: flex-start;
    width: 350px;
    margin-left: 40px;
    img {
      margin-top: auto;
    }
  }
}

.heroAppIcons {
  display: flex;
  padding-top: 12px;
  padding-bottom: 30px;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  @media @sm {
    padding-bottom: 80px;
    padding-top: 24px;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    align-items: unset;
  }
}

.stripe {
  background-color: #002b3a;
  color: #fff;
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.25);
  position: relative;
}

.callouts {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 12px 0;

  @media @sm {
    padding: 0;
    flex-direction: row;
  }
}

.callout {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px;

  @media @sm {
    padding: 24px;
  }
}

.row {
  display: flex;
  text-align: center;
  padding-top: 26px;
  flex-direction: column;

  @media @sm {
    flex-direction: row;
    text-align: left;
    align-items: center;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;
  }
}
.row__reverse {
  flex-direction: column;

  @media @sm {
    flex-direction: row-reverse;
  }
}

.rowImg {
  flex: 0 0 auto;
  margin-inline: auto;
}

.download {
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  padding: 36px 0;

  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
}

.rowOne {
  padding-top: 40px;
  @media @sm {
    padding-top: 65px;
    h2 {
      margin-top: -25px;
    }
  }
}

.rowTwo {
  @media @sm {
    .rowImg {
      img {
        margin-left: -19px;
        max-width: 397px;
      }
    }
  }
}

.rowThree {
  padding-bottom: 24px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;